import React from "react";
import renderColor from './renderColor';
import Slide from '@material-ui/core/Slide';
import Icon from '@material-ui/core/Icon';

import remark from 'remark'
import remarkHTML from 'remark-html'


export default function SingleCourse ({course, color, index}) {
    const {
        courseName,
        cost, 
        date, 
        deadline, 
        instructor, 
        location, 
        time,
        link
        } = course;
    let courseDate = date.split('T')[0]
    let timeoutTime = 400 + (index * 400)

    const md = course.body
    let myContent = remark()
    .use(remarkHTML)
    .processSync(md)
    .toString()

    return (
        <Slide direction="right" timeout={timeoutTime} in={true} mountOnEnter unmountOnExit>
            <div className="single-course">
                <div className={`title ${renderColor(color)}`}>
                    <h2>{courseName}</h2>
                </div>
                <div className="content">
                    <div className="information">
                        <ul className="info-list">
                            <li className="place"><Icon>place</Icon>{location}</li>
                            <li className="date"><Icon>calendar_today</Icon>{courseDate}</li>
                            <li className="time"><Icon>access_time</Icon>{time}</li>
                        </ul>
                    </div>
                    <div className="description">
                        <h4>Om kursen:</h4>  
                        <div dangerouslySetInnerHTML={{__html: myContent}}/>
                    </div>
                    <div className="leader">
                        <div className="deadline-wrapper">
                            <p><span style={{fontWeight: 'bold'}}>Ledare:</span> {instructor}</p>
                            <p className="deadline">Sista anmälningsdatum: {deadline}</p>
                        </div>
                        {cost !== 0 ? (
                           <div className="cost-wrapper">
                            <span style={{fontWeight: 'bold'}}>Kostnad: <span style={{color: 'red'}}>{cost}:-</span></span>
                        </div> 
                        ) : ''}
                        
                    </div>
                </div>
                {
                    link && link.toLowerCase() !== 'ingen länk' ? (
                        <a href={link ? link : '/kurser'} className="sign-up">
                            Anmäl dig här
                        </a>

                    ) : ''
                }
            </div>
        </Slide>
    );

}