export default function renderColor(color){
        switch(color){
            case 'Rosa':
                return 'pink'
            case 'Lila':
                return 'purple'
            case 'Turkosa':
                return 'turqoise'
            case 'Gul':
                return 'yellow'
            default:
                return ''
                
        }
    }