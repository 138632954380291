import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import SingleCourse from '../components/Courses/SingleCourse'

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

export const CoursesTemplate = ({location, data, helmet }) => {

  let allCourses = data.coursesList;

  return (
    <section className="course-events">
      <Breadcrumb path={location.pathname} title={data.title}/>
      {helmet || ''}
      <div className="course-title d-flex d-md-none" xs={12}>
        <h2>Kurser i {data.title}</h2>
      </div>
      <Container className="course-events-wrapper">
        <Grid container spacing={3}>
          <Grid item className="course-main-content" xs={12} md={8}>

            <Grid container spacing={3}>
               {allCourses && allCourses.map((course, i) => {
                  return (
                    <Grid item xs={12} sm={6} key={i}>
                      <SingleCourse course={course} key={i} index={i} color={data.color}/>
                    </Grid>
                  )
                })}
                {!allCourses && 
                <Grid item xs={12}>
                  <h1>Det finns inga kurser just nu. Kom tillbaka senare!</h1>
                  <h4>Se till att följa oss på facebook för att se när nya kurser dyker upp.</h4>
                  </Grid>}
              </Grid>

          </Grid>
          <Grid item className="course-side-content d-none d-lg-block" xs={12} md={4}>
            <Box>
              <Card className="side-card">
                <CardHeader
                  className="title"
                  title="Anmälan"
                />
                <CardContent>
                  <Typography paragraph>
                    Anmälan är bindande, och din plats är säkrad
                    efter betalning skett.
                  </Typography>
                  <Typography paragraph>
                    Paranmälan har förtur, meddela i din anmälan
                    vem din partner är.
                  </Typography>
                </CardContent>
              </Card>

              <Card className="side-card">
                <CardHeader
                  className="title"
                  title="Frågor?"
                />
                <CardContent>
                 <List>
                   <ListItem>
                     <ListItemIcon>
                       <Icon>mail</Icon>
                     </ListItemIcon>
                     <ListItemText primary="info@kvarntakt20.se" />
                   </ListItem>
                   <ListItem>
                     <ListItemIcon>
                       <Icon>smartphone</Icon>
                     </ListItemIcon>
                     <ListItemText primary="0735909304" />
                   </ListItem>
                 </List>
                </CardContent>
              </Card>

            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

const CoursesPage = ({location, data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <CoursesTemplate
        helmet={
          <Helmet titleTemplate="%s | Kurser">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        data={post.frontmatter}
        location={location}
      />
    </Layout>
  )
}

CoursesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CoursesPage

export const coursesPageQuery = graphql`
  query KurserPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        color
        coursesList{
            about,
            body,
            date,
            time,
            cost,
            courseName,
            location,
            instructor,
            deadline,
            link
        }
      }
    }
  }
`
